
<template>
<v-app>
    <dashboard-core-view />

    <dashboard-core-settings />
    
    
<div style="background-color:#1e1e1e94; height:100%">

  <div class="text-center" style="text-align: center;">
      <img src="https://maestrix.grupoparque.com.br/LOGOSVG8.svg" width="300" height="220" style="margin-top:12px">

  

  </div>

<v-card
    class="mx-auto" elevation="12"
    color="#FFFFFF"
    max-width="540"
  >

   <v-form
        ref="form"
        v-model="valid"
        lazy-validation>

    <v-card-title style="background-color:#439ad8;color:white;font-size:16px">
      <v-icon
        left  color="white darken-2"
      >
        mdi-account-outline
      </v-icon>
      Acesso do Colaborador
    </v-card-title>

    <v-card-text class="" style="margin-top:30px">
          <v-text-field 
            label="Usuário" Required :rules="[v => !!v || 'Campo Obrigatório']"
            filled v-model="user.username"
            prepend-inner-icon="mdi-email"
          ></v-text-field>

          <v-text-field filled  Required :rules="[v => !!v || 'Campo Obrigatório']"
            label="SENHA" type="password" v-model="user.password"
            prepend-inner-icon="mdi-key"
            
          ></v-text-field>

         <v-alert :value="alert_login" dense outlined type="error">
          {{msg_login}}
        </v-alert>


        </v-card-text>
          
         

    <v-card-actions>
          <v-spacer></v-spacer>

     

        <v-btn
          color=success class="white--text"
          @click="handleLogin"
          :loading="btn_login_load"

        >
        <b>Entrar</b>
              <v-icon right dark>   mdi-check</v-icon>

          
        </v-btn>
    </v-card-actions>

   </v-form>
  </v-card>

    <div class="text-center" style="text-align: center;">
      <img src="../../assets/logo_grupo.png" width="120" height="80" style="margin-top:18px">
  </div>



</div>
</v-app>

</template>

<script>
import User from '../../models/user'



  export default {
    name: 'Login',
    data () {
      return {
         components: {
          DashboardCoreView: () => import('../dashboard/components/core/View'),
        },
        
        user: new User('', ''),
        loading: false,
        message: '',
        chip2: false,
        expandOnHover: false,
        alert_login: false,
       expandOnHover: false,

    }
    },

    computed: {
      loggedIn () {
        return this.$store.state.auth.status.loggedIn
      },
    },
    created () {
      this.$store.dispatch('auth/logout');
      //this.$router.push('/pages/login');
    
      //if (this.loggedIn) {
       // this.$router.push('/dashboard')
     // }

    },
    methods: {
      handleLogin () {        

        this.loading = true
        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.showload = true;
              this.$router.push('/adm/')
            },
            error => {
              this.loading = false
              this.msg_login = 'Usuário ou senha não confere',
              this.alert_login = true
            },
          )
        }
      },
    },
  }
</script>
